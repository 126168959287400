import React from 'react';
import PropTypes from 'prop-types';

import Integrate from '@components/envios/integrate';
import Banner from '@components/envios/banner';
import Questions from '@components/envios/questions';

import Benefits from '../benefits';

const faqs = [
  {
    question: 'Em quais produtos vou oferecer Envios do Mercado Livre?',
    answer: (
      <>
        Em todos os produtos que possam ser enviados por Correios ou
        transportadoras. Caso você esteja em dúvida sobre algum produto, basta
        conferir{' '}
        <a
          href="https://www.mercadolivre.com.br/ajuda/3163"
          target="_blank"
          rel="noopener noreferrer"
        >
          quais são as dimensões máximas permitidas para enviar pacotes
        </a>
        .
      </>
    ),
  },
  {
    question: 'Em quais produtos eu vou oferecer frete grátis e descontos?',
    answer: (
      <>
        Nos produtos novos a partir de R$ 79 você oferecerá frete grátis e
        descontos significativos, terá desconto nos custos conforme a sua
        reputação e a categoria do produto.{' '}
        <a
          href="https://www.mercadolivre.com.br/ajuda/3362"
          target="_blank"
          rel="noopener noreferrer"
        >
          Conferir custos e descontos
        </a>
      </>
    ),
  },
  {
    question: 'Quando o dinheiro da minha venda ficará disponível?',
    answer: (
      <>
        Se tiver reputação por vender no Mercado Livre e o produto for novo, seu
        dinheiro estará disponível 8 dias após a entrega do produto.
        <br />
        <br />
        Se não tiver reputação como vendedor no Mercado Livre ou o produto for
        usado, seu dinheiro estará disponível 12 dias após a entrega do produto.
        <br />
        <br />
        <a
          href="https://reputacao.mercadolivre.com.br/myReputation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Consultar minha reputação
        </a>
      </>
    ),
  },
  {
    question: 'Onde os produtos são despachados?',
    answer: (
      <>
        Você pode levar seus produtos a qualquer um dos nossos{' '}
        <a
          href="http://envios.mercadolivre.com.br/agenciesList/addAddressForAgenciesMap"
          target="_blank"
          rel="noopener noreferrer"
        >
          agências Mercado Livre
        </a>
      </>
    ),
  },
  {
    question: 'Quando devo despachar os produtos?',
    answer: (
      <>
        Assim que vender, prepare e despache o produto o mais rápido possível
        para oferecer a melhor experiência aos seus clientes e não afetar{' '}
        <a
          href="https://www.mercadolivre.com.br/ajuda/866"
          target="_blank"
          rel="noopener noreferrer"
        >
          sua reputação por vender no Mercado Livre
        </a>
        . Em cada venda, te daremos orientações para despachar no prazo.
      </>
    ),
  },
];

const Body = (props) => {
  const { className } = props;

  return (
    <div className={className}>
      <Banner
        title="Envie suas vendas com o Mercado Livre"
        text="Você oferecerá frete grátis com até 50% de desconto no serviço, e os seus produtos estarão em destaque nos resultados de busca."
        image="envelope-pt"
      />
      <Benefits />
      <Questions title="Confira os detalhes" faqs={faqs} />
      <Integrate
        title={
          <>
            Sejamos uma equipe
            <br />
            Envie suas vendas com o Mercado Livre
          </>
        }
        link="https://www.mercadolivre.com.br/anuncios/"
        buttonText="Ir para Anúncios"
      />
    </div>
  );
};

Body.propTypes = {
  className: PropTypes.string,
};

Body.defaultProps = {
  className: 'body',
};

export default Body;
