import React from 'react';

import { hydrate } from 'nordic/hydrate';
import I18n from 'nordic/i18n';
import I18nProvider from 'nordic/i18n/I18nProvider';
import ImageProvider from 'nordic/image/provider';

import View from '../pages/envios/MLB/view';
import '../pages/envios/MLB/styles.scss';

const smoothscroll = require('smoothscroll-polyfill');

smoothscroll.polyfill();

/**
 * Get server state
 */
const data = window.__PRELOADED_STATE__;
const { translations, siteId, lowEnd, deviceType, company, imagesPrefix } =
  data;

/**
 * i18n
 */
const i18n = new I18n({ translations });

/**
 * Mount View on client
 */
hydrate(
  <I18nProvider i18n={i18n}>
    <ImageProvider prefix={imagesPrefix}>
      <View
        view="enviosMLB"
        siteId={siteId}
        lowEnd={lowEnd}
        deviceType={deviceType}
        company={company}
      />
    </ImageProvider>
  </I18nProvider>,
  document.getElementById('root-app'),
);
