import React from 'react';

import DispatchSteps from '@components/envios/dispatch-steps';
import MEBenefits from '@components/envios/me-benefits';
import FSBenefits from '@components/envios/fs-benefits';

const fsBenefitList = [
  {
    title: 'Cobrimos até 50% do custo dos seus fretes grátis',
    text: (
      <>
        No Mercado Livre, todas as suas vendas a partir de R$ 79 têm frete
        grátis e descontos significativos e nós pagamos até 50% do custo desses
        envios.
        <div className="separator" />
        <a
          href="https://www.mercadolivre.com.br/ajuda/CustosdefretegratispeloMercadoEnvios_3362"
          target="_blank"
          rel="noopener noreferrer"
        >
          Conferir tarifas e descontos
        </a>
      </>
    ),
    image: 'accesible',
  },
  {
    title: 'Melhore a exposição dos seus anúncios',
    text: 'Os seus produtos com frete grátis terão um filtro exclusivo e você poderá ganhar melhor posicionamento nos resultados de busca.',
    image: 'outstanding',
  },
];

const dispatchStepList = [
  {
    icon: 'printer',
    subTitle: 'Cada venda gera uma etiqueta de envio.',
  },
  {
    icon: 'box',
    subTitle: 'Basta preparar o seu produto.',
  },
  {
    icon: 'place',
    subTitle: (
      <>
        E levá-lo a uma{' '}
        <a
          href="http://envios.mercadolivre.com.br/agenciesList/addAddressForAgenciesMap"
          target="_blank"
          rel="noopener noreferrer"
        >
          agência Mercado Livre
        </a>
        .
      </>
    ),
  },
];

const meBenefitList = [
  {
    image: 'payment',
    title: 'Receba mais rápido',
    text: 'Vamos liberar o dinheiro entre 5 a 9 dias após o produto chegar.',
  },
  {
    image: 'secure',
    title: 'Conte com o nosso respaldo',
    text: 'Todos os seus pacotes estão assegurados. Se houver algum imprevisto, nós cobrimos você.',
  },
  {
    image: 'tracking',
    title: 'Os envios têm acompanhamento',
    text: 'Você e o seu comprador podem acompanhar o envio o tempo todo.',
  },
  {
    image: 'coordination',
    title: 'Você não precisa combinar cada envio',
    text: 'O comprador seleciona o envio ao comprar e paga pelo Mercado Pago.',
  },
  {
    image: 'cart',
    title: 'Venda mais com o carrinho',
    text: 'Os seus compradores poderão adicionar vários dos seus produtos ao carrinho e economizar nos custos de frete.',
  },
  {
    image: 'reputation',
    title: 'Melhore sua reputação',
    text: 'O seu bom serviço e a velocidade do envio dos produtos potencializam a sua reputação.',
  },
];

const Benefits = () => (
  <div className="benefits">
    <div className="push-benefits">
      <FSBenefits benefitList={fsBenefitList} />
    </div>

    <DispatchSteps title="Suas tarefas mais simples" steps={dispatchStepList} />

    <MEBenefits title="E mais benefícios" benefitList={meBenefitList} />
  </div>
);

export default Benefits;
